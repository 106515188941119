



























import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import NumberDisplay from "@/components/NumberDisplay.vue";
import HeaderMenu from "@/components/HeaderMenu.vue";
import HeaderLogo from "@/components/HeaderLogo.vue";
import HeaderToolbar from "@/components/HeaderToolbar.vue";
import ChargeInfoCard from "@/components/ChargeInfoCard.vue";
import CarSettingsCard from "@/components/CarSettingsCard.vue";
import BatteryInfoCard from "@/components/BatteryInfoCard.vue";
import ConsumptionInfoCard from "@/components/ConsumptionInfoCard.vue";

@Component({
  name: "Home",
  components: {
    NumberDisplay,
    HeaderMenu,
    HeaderLogo,
    HeaderToolbar,
    ChargeInfoCard,
    CarSettingsCard,
    BatteryInfoCard,
    ConsumptionInfoCard,
  },
})
export default class Home extends Vue {
  @Action("mqtt/subscribe")
  private subscribe!: (key: string) => Promise<void>;

  @Getter("mqtt/isOnline")
  private online!: boolean;

  @Getter("mqtt/isCharging")
  private stateCharging!: boolean;

  private scrollPosition!: number;

  private get charging() {
    return this.online && this.stateCharging;
  }

  private mounted() {
    setInterval(() => {
      this.$store.commit("mqtt/UPDATE_TIMESTAMP", Date.now());
    }, 10000);

    window.onscroll = this.onScroll.bind(this);

    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        const key =
          "BOLHCw9oZK6r_Lq-tkHreMNMy_oacg3hqmG5Na4j9Tkg06v2f-95R8xw11WL3UJeAiwvrSuViTa_-mUO5A1ocdM";

        this.subscribe(key);
      }
    });
  }

  onScroll(e: Event) {
    this.scrollPosition = window.scrollY;
  }

  private data() {
    return {
      scrollPosition: 0,
      version: process.env.VUE_APP_VERSION,
      commit: process.env.VUE_APP_COMMIT,
    };
  }
}
