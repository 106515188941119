




















import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component
export default class HeaderLogo extends Vue {

  @Getter("mqtt/getDataTimestamp")
  private timestamp!: number;

  @Getter("mqtt/isOnline")
  private online!: boolean;

  private get timestampString() {
    return new Date(this.timestamp).toLocaleString();
  }

}
