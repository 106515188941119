


































import { Component, Prop, Vue } from "vue-property-decorator";
import NumberDisplay from "@/components/NumberDisplay.vue";
import { Getter } from "vuex-class";

@Component({
  components: {
    NumberDisplay,
  },
})
export default class ConsumptionInfoCard extends Vue {
  @Getter("mqtt/getConsumption")
  private consumption!: number[];

  private get totalConsumption() {
    if (this.consumption.length === 0) {
      return 0;
    }

    return this.consumption.reduceRight((result, current) => result + current);
  }

  private get averageConsumption() {
    if (this.consumption.length === 0) {
      return 0;
    }

    return (this.totalConsumption * 1000) / this.consumption.length;
  }

  private get consumptionLabels() {
    return this.consumption.map((value, index) => {
      if ((index + this.consumption.length) % 3 == 0) {
        return `${(value * 1000).toFixed(0)}Wh`;
      } else {
        return " ";
      }
    });
  }
}
