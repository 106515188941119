import { Module } from 'vuex'
import { Client, connect } from 'mqtt'
import Vue from 'vue'
import { ChargerState } from '@/types'
import { DeviceUUID } from 'device-uuid'

export interface CarData {
    timestamp: number    
    soc: number
    energy: number
    energyFull: number
    range: number
    charger: {
        state: ChargerState
        energy: number
        rate: number
        range: number
        threePhase: boolean
        rateKm: number
        timeLeft: number
        endTimestamp: number
        limit: number
        powerLimit: number
    }
    battery: {
        voltage: number
        temperature: {
            min: number
            max: number
        }
    },
    consumption: number[],
    heaterOverride: boolean,
}

export interface MqttState {
    client?: Client
    username: string
    online: boolean
    timestamp: number
    data: CarData
}

export enum AppControlReturnCode {
    Failed = 0,
    Success = 1,
    Permission = 2
}

const stateTopicMap: [RegExp, string][] = [
    [/audi-a2\/\w+\/timestamp$/, 'timestamp'],
    [/audi-a2\/\w+\/battery\/soc$/, 'soc'],
    [/audi-a2\/\w+\/battery\/voltage$/, 'battery.voltage'],
    [/audi-a2\/\w+\/battery\/temperature\/min$/, 'battery.temperature.min'],
    [/audi-a2\/\w+\/battery\/temperature\/max$/, 'battery.temperature.max'],
    [/audi-a2\/\w+\/battery\/energy$/, 'energy'],
    [/audi-a2\/\w+\/battery\/energyFull$/, 'energyFull'],
    [/audi-a2\/\w+\/range$/, 'range'],
    [/audi-a2\/\w+\/charger\/state$/, 'charger.state'],
    [/audi-a2\/\w+\/charger\/rate$/, 'charger.rate'],
    [/audi-a2\/\w+\/charger\/range$/, 'charger.range'],
    [/audi-a2\/\w+\/charger\/threePhase$/, 'charger.threePhase'],
    [/audi-a2\/\w+\/charger\/energy$/, 'charger.energy'],
    [/audi-a2\/\w+\/charger\/rate_km$/, 'charger.rateKm'],
    [/audi-a2\/\w+\/charger\/time_left$/, 'charger.timeLeft'],
    [/audi-a2\/\w+\/charger\/end_timestamp$/, 'charger.endTimestamp'],
    [/audi-a2\/\w+\/charger\/limit$/, 'charger.limit'],
    [/audi-a2\/\w+\/charger\/power_limit$/, 'charger.powerLimit'],
    [/audi-a2\/\w+\/consumption$/, 'consumption'],
    [/audi-a2\/\w+\/config\/heater_override$/, 'heaterOverride'],
]

const mqtt: Module<MqttState, {}> = {
    state: {
        username: '',
        online: false,
        timestamp: Date.now(),
        data: {
            timestamp: 0,
            soc: 0,
            energy: 0,
            energyFull: 0,
            range: 0,
            charger: {
                state: ChargerState.Off,
                energy: 0,
                rate: 0,
                range: 0,
                threePhase: false,
                rateKm: 0,
                timeLeft: 0,
                endTimestamp: 0,
                limit: 0,
                powerLimit: 0,
            },
            battery: {
                voltage: 0,
                temperature: {
                    min: 0,
                    max: 0,
                },
            },
            consumption: [],
            heaterOverride: false,
        },
    },
    getters: {
        isOnline: (state) => Math.abs(state.timestamp - state.data.timestamp) < 120 * 1000,
        getDataTimestamp: (state) => state.data.timestamp,
        getSoc: (state) => state.data.soc,
        getRange: (state) => state.data.range,
        getBatteryVoltage: (state) => state.data.battery.voltage,
        getBatteryTemperature: (state) => state.data.battery.temperature,
        getBatteryEnergy: (state) => state.data.energy,
        isCharging: (state) => state.data.charger.state != ChargerState.Off,
        isChargingThreePhase: (state) => state.data.charger.threePhase,
        getChargeRate: (state) => state.data.charger.rate / 1000,
        getChargeEnergy: (state) => state.data.charger.energy,
        getChargeRange: (state) => state.data.charger.range,
        getChargeRateKm: (state) => state.data.charger.rateKm,
        getChargeTimeLeft: (state) => state.data.charger.timeLeft,
        getChargeEndTimestamp: (state) => state.data.charger.endTimestamp,
        getChargeLimit: (state) => state.data.charger.limit,
        getChargePowerLimit: (state) => state.data.charger.powerLimit,
        getConsumption: (state) => state.data.consumption,
        getHeaterOverride: (state) => state.data.heaterOverride,
    },
    mutations: {
        SET_USERNAME: (state, username) => {
            state.username = username
        },
        PROCESS_MESSAGE: (
            state,
            { topic, payload }: { topic: string; payload: string }
        ) => {
            console.log(topic + ': ' + payload)

            for (const [reg, key] of stateTopicMap) {
                if (reg.test(topic)) {
                    const keys = key.split('.')
                    let obj: any = state.data

                    for (var i = 0; i < keys.length - 1; ++i) {
                        obj = obj[keys[i]]
                    }

                    Vue.set(obj, keys[keys.length - 1], JSON.parse(payload))
                    break
                }
            }
        },
        UPDATE_TIMESTAMP: (state, timestamp: number) => {
            state.timestamp = timestamp;
        }
    },
    actions: {
        connect: (
            { state, commit },
            { username, password }: { username: string; password: string }
        ) => {
            commit('SET_USERNAME', username)

            return new Promise<void>((resolve, reject) => {
                state.client = connect('wss://mqtt.fgi.cloud', {
                    username,
                    password,
                    protocolVersion: 5
                })

                state.client.on('error', (error) => {
                    reject(error)
                })

                state.client.on('connect', () => {
                    state.client!.on('message', (topic, payload) => {
                        commit('PROCESS_MESSAGE', { topic, payload })
                    })

                    state.client!.subscribe(`audi-a2/${username}/#`)

                    resolve()
                })
            })
        },
        logout: async ({ state, commit }) => {
            const uuid = localStorage.getItem('notification-uuid')

            window.localStorage.removeItem('mqtt-username')
            window.localStorage.removeItem('mqtt-password')
            window.localStorage.removeItem('notification-uuid')

            if (uuid && state.client) {
                state.client.publish(
                    `audi-a2/${state.username}/notification/${uuid}/endpoint`,
                    '',
                    { retain: true }
                )
                state.client.publish(
                    `audi-a2/${state.username}/notification/${uuid}/keys/p256dh`,
                    '',
                    { retain: true }
                )
                state.client.publish(
                    `audi-a2/${state.username}/notification/${uuid}/keys/auth`,
                    '',
                    { retain: true }
                )
            }

            const serviceWorker = await navigator.serviceWorker.getRegistration()
            if (serviceWorker) {
                const subscription = await serviceWorker.pushManager.getSubscription()
                if (subscription) {
                    await subscription.unsubscribe()
                }
            }

            if (state.client) {
                state.client.end()
                state.client = undefined
            }

            commit('SET_USERNAME', '')

            location.reload()
        },
        subscribe: async ({ state }, key: string) => {
            if (!state.client) {
                throw 'not connected!'
            }

            let uuid = localStorage.getItem('notification-uuid')

            if (uuid) {
                return
            }

            uuid = new DeviceUUID().get()


            const serviceWorker = await navigator.serviceWorker.getRegistration()
            const subscription = await serviceWorker?.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: key,
            })

            if (!subscription) {
                throw 'subscription failed!'
            }

            const subscriptionJson = subscription.toJSON()

            state.client.publish(
                `audi-a2/${state.username}/notification/${uuid}/endpoint`,
                subscriptionJson.endpoint!,
                { retain: true }
            )
            state.client.publish(
                `audi-a2/${state.username}/notification/${uuid}/keys/p256dh`,
                subscriptionJson.keys!.p256dh,
                { retain: true }
            )
            state.client.publish(
                `audi-a2/${state.username}/notification/${uuid}/keys/auth`,
                subscriptionJson.keys!.auth,
                { retain: true }
            )

            localStorage.setItem('notification-uuid', uuid)
        },
        sendMqttRequest: async ({ state }, {topic, value}: { topic: string, value: string }) => {

            return new Promise<void>((resolve, reject) => {
                if (!state.client) {
                    return reject('Keine Verbindung zum Server');
                }
            
                const rand = Math.round(Math.random() * 0xFFFF);
                const responseTopic = `audi-a2/${state.username}/config/write/${topic}/ack${ rand.toString(16) }`

                let timeout: NodeJS.Timeout;

                const handler = (topic: string, payload: Buffer) => {
                    if (topic === responseTopic) {
                        const value = parseInt(payload.toString()) as AppControlReturnCode;
                        
                        clearTimeout(timeout);
                        state.client?.off('message', handler);

                        if (value === AppControlReturnCode.Success) {
                            resolve();
                        } else if (value === AppControlReturnCode.Failed) {
                            reject('validation failed');
                        } else if (value === AppControlReturnCode.Permission) {
                            reject('permission error');
                        } else {
                            reject();
                        }
                    }
                }
                
                timeout = setTimeout(() => {
                    state.client?.off('message', handler);
                    reject('Timeout: no response')
                }, 15000);
                state.client.on('message', handler);

                state.client.publish(
                    `audi-a2/${state.username}/config/write/${topic}`,
                    value,
                    { retain: true,
                    properties: {
                        messageExpiryInterval: 15,
                        responseTopic
                    } }
                )
            });

        },
        setPowerLimit1P: ({ dispatch }, limit: number) => {
            return dispatch('sendMqttRequest', { topic: 'power_limit_1p', value: limit.toString() });
        },
        setPowerLimit3P: ({ dispatch }, limit: number) => {
            return dispatch('sendMqttRequest', { topic: 'power_limit_3p', value: limit.toString() });
        },
        setChargeLimit: ({ dispatch }, limit: number) => {
            return dispatch('sendMqttRequest', { topic: 'charge_limit', value: limit.toString() });
        },
        setChargeEnable: ({ dispatch }, value: boolean) => {
            return dispatch('sendMqttRequest', { topic: 'charge_enable', value: value? '1' : '0' });
        },
        setHeaterOverride: ({ dispatch }, value: boolean) => {
            return dispatch('sendMqttRequest', { topic: 'heater_override', value: value? '1' : '0' });
        }
    },
    namespaced: true,
}

export default mqtt
