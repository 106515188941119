


























































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import NumberDisplay from "@/components/NumberDisplay.vue";
import { Getter } from "vuex-class";

@Component({
  components: {
    NumberDisplay,
  },
})
export default class ChargeInfoCard extends Vue {
  @Getter("mqtt/getSoc")
  private soc!: number;

  @Getter("mqtt/isChargingThreePhase")
  private threePhase!: number;

  @Getter("mqtt/getChargeTimeLeft")
  private timeLeft!: number;

  @Getter("mqtt/getChargeEndTimestamp")
  private endTimestamp!: number;

  @Getter("mqtt/getChargeRateKm")
  private rateKm!: number;

  @Getter("mqtt/getChargeLimit")
  private chargeLimit!: number;

  @Getter("mqtt/getChargePowerLimit")
  private chargePowerLimit!: number;

  private chargeEndConfigSending!: boolean;
  private chargeEndConfigError!: string;
  private chargeEndDialog!: boolean;

  private displayEndTimestamp!: boolean;

  private toggleDisplayTimer!: NodeJS.Timer;

  private get chargeProgress() {
    return Math.min((this.soc / this.chargeLimit) * 100, 100);
  }

  private get endTimestampString() {
    return new Date(this.endTimestamp).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
  }

  private mounted() {
    this.toggleDisplayTimer = setInterval(() => {
      this.displayEndTimestamp = !this.displayEndTimestamp;
    }, 10000)
  }

  private beforeDestroy() {
    clearInterval(this.toggleDisplayTimer);
  }

  private sendChargeEndConfig(enable: boolean) {
    this.chargeEndConfigSending = true;
    this.chargeEndConfigError = "";

    this.$store
      .dispatch("mqtt/setChargeEnable", enable)
      .then(() => {
        this.chargeEndConfigSending = false;
        this.chargeEndDialog = false;
      })
      .catch((e) => {
        this.chargeEndConfigSending = false;
        this.chargeEndConfigError = e.toString();
      });
  }

  @Watch("chargeEndDialog")
  private onDialogOpen() {
    this.chargeEndConfigError = "";
  }

  private data() {
    return {
      displayEndTimestamp: false,
      chargeEndConfigSending: false,
      chargeEndConfigError: "",
      chargeEndDialog: false,
    };
  }
}
