



















































































import { Component, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";
import update from "./mixins/update";

@Component({
  name: "App",
  mixins: [update],
})
export default class AppComponent extends Vue {
  private authenticated!: boolean;
  private loading!: boolean;

  private username!: string;
  private password!: string;

  @Action("mqtt/connect")
  private connect!: (credentials: {
    username: string;
    password: string;
  }) => Promise<void>;

  private async mounted() {
    const username = window.localStorage.getItem("mqtt-username");
    const password = window.localStorage.getItem("mqtt-password");

    if (username && password) {
      await this.tryLogin(username, password);
    }
  }

  private async tryLogin(username: string, password: string) {
    try {
      this.loading = true;

      await this.connect({
        username,
        password,
      });

      this.authenticated = true;
      this.loading = false;

      window.localStorage.setItem("mqtt-username", username);
      window.localStorage.setItem("mqtt-password", password);

      return true;
    } catch (e) {
      console.log(e);
      this.authenticated = false;
      this.loading = false;

      window.localStorage.removeItem("mqtt-username");
      window.localStorage.removeItem("mqtt-password");

      return false;
    }
  }

  private async login(evt: Event) {
    evt.preventDefault();
    this.tryLogin(this.username, this.password);
  }

  private data() {
    return {
      authenticated: false,
      loading: false,
      username: "",
      password: "",
    };
  }
}
