



























































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import NumberDisplay from "@/components/NumberDisplay.vue";
import { Getter } from "vuex-class";

@Component({
  components: {
    NumberDisplay,
  },
})
export default class CarSettingsCard extends Vue {
  @Getter("mqtt/getChargeLimit")
  private chargeLimit!: number;

  @Getter("mqtt/getChargePowerLimit")
  private chargePowerLimit!: number;

  @Getter("mqtt/isChargingThreePhase")
  private threePhase!: number;

  @Getter("mqtt/getHeaterOverride")
  private heaterOverride!: boolean;

  @Getter("mqtt/isCharging")
  private charging!: boolean;

  private powerLimitConfigData!: number;
  private powerLimitConfigSending!: boolean;
  private powerLimitConfigError!: string;

  private chargeLimitConfigData!: number;
  private chargeLimitConfigSending!: boolean;
  private chargeLimitConfigError!: string;

  private heaterOverrideConfigSending!: boolean;
  private heaterOverrideConfigError!: string;

  @Watch("chargePowerLimit")
  private onPowerLimitChanged(value: number) {
    this.powerLimitConfigData = value;
  }

  private get configPowerLimit() {
    return this.chargePowerLimit;
  }

  private set configPowerLimit(value) {
    this.powerLimitConfigData = value;
    this.powerLimitConfigError = "";
  }

  private sendPowerLimitConfig() {
    this.powerLimitConfigSending = true;

    const action = this.threePhase
      ? "mqtt/setPowerLimit3P"
      : "mqtt/setPowerLimit1P";
    this.$store
      .dispatch(action, this.powerLimitConfigData)
      .then(() => {
        this.powerLimitConfigSending = false;
      })
      .catch((e) => {
        this.powerLimitConfigData = this.chargePowerLimit;
        this.powerLimitConfigSending = false;
        this.powerLimitConfigError = e.toString();
      });
  }

  @Watch("chargeLimit")
  private onChargeLimitChanged(value: number) {
    this.chargeLimitConfigData = value;
  }

  private get configChargeLimit() {
    return this.chargeLimit;
  }

  private set configChargeLimit(value) {
    this.chargeLimitConfigData = value;
    this.chargeLimitConfigError = "";
  }

  private sendChargeLimitConfig() {
    this.chargeLimitConfigSending = true;

    this.$store
      .dispatch("mqtt/setChargeLimit", this.chargeLimitConfigData)
      .then(() => {
        this.chargeLimitConfigSending = false;
      })
      .catch((e) => {
        this.chargeLimitConfigData = this.chargeLimit;
        this.chargeLimitConfigSending = false;
        this.chargeLimitConfigError = e.toString();
      });
  }

  private get selected() {
    const result: string[] = [];

    if (this.heaterOverride) {
      result.push("heater");
    }

    return result;
  }

  private set selected(value) {
    const heater = value.includes("heater");

    if (heater != this.heaterOverride) {
      this.heaterOverrideConfigSending = true;
      this.heaterOverrideConfigError = '';

      this.$store
        .dispatch("mqtt/setHeaterOverride", heater)
        .then(() => {
          this.heaterOverrideConfigSending = false;
        })
        .catch((e) => {
          this.heaterOverrideConfigSending = false;
          this.heaterOverrideConfigError = e.toString();
        });
    }
  }

  private data() {
    return {
      powerLimitConfigData: this.$store.getters["mqtt/getChargePowerLimit"],
      powerLimitConfigSending: false,
      powerLimitConfigError: "",
      chargeLimitConfigData: this.$store.getters["mqtt/getChargeLimit"],
      chargeLimitConfigSending: false,
      chargeLimitConfigError: "",
      heaterOverrideConfigSending: false,
      heaterOverrideConfigError: "",
    };
  }
}
