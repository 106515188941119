




















import { Component, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";

@Component
export default class HeaderMenu extends Vue {
  @Action("mqtt/logout")
  private logout!: () => void;
}
