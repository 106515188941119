








































































import { Component, Prop, Vue } from "vue-property-decorator";
import NumberDisplay from "@/components/NumberDisplay.vue";
import { Getter } from "vuex-class";

@Component({
  components: {
    NumberDisplay,
  },
})
export default class HeaderToolbar extends Vue {
  @Getter("mqtt/isOnline")
  private online!: boolean;

  @Getter("mqtt/getSoc")
  private soc!: number;

  @Getter("mqtt/getRange")
  private range!: number;

  @Getter("mqtt/isCharging")
  private stateCharging!: boolean;

  @Getter("mqtt/getChargeRate")
  private chargeRate!: number;

  @Getter("mqtt/getChargeRange")
  private chargeRange!: number;

  @Getter("mqtt/getChargeEnergy")
  private chargeEnergy!: number;

  @Prop({ type: Number, default: 0 })
  private scrollPosition!: number;

  private get batteryIcon() {
    const soc = Math.min(Math.round(this.soc / 10), 10);

    if (soc === 0) {
      return `battery-outline`;
    } else if (soc === 10) {
      return "battery";
    } else {
      return `battery-${soc}0`;
    }
  }

  private get charging() {
    return this.online && this.stateCharging;
  }

  private get headerSticky() {
    return this.scrollPosition > 120;
  }

}
