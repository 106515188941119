





























import { Component, Prop, Vue } from "vue-property-decorator";
import NumberDisplay from "@/components/NumberDisplay.vue";
import { Getter } from "vuex-class";

@Component({
  components: {
    NumberDisplay,
  },
})
export default class BatteryInfoCard extends Vue {
  @Getter("mqtt/getBatteryVoltage")
  private voltage!: number;

  @Getter("mqtt/getBatteryTemperature")
  private temperature!: { min: number; max: number };
}
