





















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class NumberDisplay extends Vue {

    @Prop({ type: Number, required: true })
    private value!: number;

    @Prop({ type: Boolean, default: false })
    private animated!: number;

    @Prop({ type: [Number, String], default: 0 })
    private format!: number | string;

    @Prop({ type: Boolean, default: false })
    private colored!: boolean;

    @Prop({ type: Boolean, default: false })
    private showSign!: boolean;

    @Prop({ type: String, default: '' })
    private suffix!: string;

    private formatValue(value: number) {
        if (typeof this.format === 'number') {
            if (this.format === 0) {
                value = Math.round(value);
            }
            if (this.showSign && value >= 0) {
                return `+${value.toFixed(this.format)}${this.suffix}`
            }
            return `${value.toFixed(this.format)}${this.suffix}`;
        }
        if (this.format === 'time') {
            const h = Math.floor(value / 60);
            const m = `00${(value % 60).toFixed(0)}`.substr(-2);

            return `${h}:${m}${this.suffix}`;
        }
    }

}
