import Vue from 'vue';

export default Vue.extend<{
    refreshing: boolean,
    registration: ServiceWorkerRegistration | null,
    updateExists: boolean
}, { 
    updateAvailable: (event: CustomEvent<ServiceWorkerRegistration>) => void,
    refreshApp: () => void
}, {}, {}>({
    data: function() {
        return {
            refreshing: false,
            registration: null,
            updateExists: false,
        }
    },
    created: function() {
        document.addEventListener<any>('swUpdated', this.updateAvailable, { once: true })

        navigator.serviceWorker.addEventListener('controllerchange', () => {
            if (this.refreshing) return
            this.refreshing = true
            
            window.location.reload()
        })
    },
    methods: {
        updateAvailable: function(event: CustomEvent<ServiceWorkerRegistration>) {
            this.registration = event.detail
            this.updateExists = true
        },

        refreshApp: function() {
            this.updateExists = false;

            if (!this.registration || !this.registration.waiting) return
            
            this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
        }
    }
})
